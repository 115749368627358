<script>
	import { beforeNavigate } from '$app/navigation';
	import { fly, slide } from 'svelte/transition';
	import { cubicOut } from 'svelte/easing';
	let open = false;
	let toggleMenu = () => {
		open = !open;
		if (open) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'scroll';
		}
	};
	beforeNavigate(() => {
		open = false;
		document.body.style.overflow = 'scroll';
	});
</script>

<nav>
	<a href="/" class="brand">Jordanian Food for Funds</a>
	<div class="links">
		<a href="/about">about</a>
		<a href="/menu">menu</a>
		<a href="/contact">contact</a>
		<a target="_blank" href="/donate">donate</a>
		<a href="/rsvp" class="btn small">RSVP</a>
	</div>
	<div class="burger">
		<button
			on:click={toggleMenu}
			class="hamburger hamburger--squeeze"
			class:is-active={open}
			type="button"
			aria-label="Menu"
			aria-controls="navigation"
		>
			<span class="hamburger-box">
				<span class="hamburger-inner"></span>
			</span>
		</button>
	</div>
</nav>
{#if open}
	<div class="links--open" transition:slide={{ y: -20, duration: 400, easing: cubicOut, delay: 0 }}>
		<a href="/about">about</a>
		<a href="/menu">menu</a>
		<a href="/contact">contact</a>
		<a target="_blank" href="/donate">donate</a>
		<a href="/rsvp/manage">manage rsvp</a>
		<a href="/rsvp" class="btn small">RSVP</a>
	</div>
{/if}

<style>
	@import url('$lib/styles/hamburgers.css');
	.links--open {
		position: absolute;
		top: 3.7rem;
		right: 0;
		left: 0;
		padding: 0em 1em;
		background: var(--bg);
		height: 100vh;
		display: flex;
		flex-direction: column;
		border-bottom: 5px solid black;
		z-index: 100;
		text-decoration: none;
	}
	.links--open a {
		padding: 0.5em 0;
		text-decoration: none;
	}
	.links--open .btn {
		margin: 2em 0;
	}
	.burger {
		display: none;
	}
	.links {
		display: flex;
		justify-content: flex-end;
		align-items: center;
	}
	nav {
		display: flex;
		padding: 1em 1.5em;
		align-items: center;

		background: var(--bg);
	}
	@media only screen and (max-width: 750px) {
		nav {
			padding: 1em 1em;
		}
	}
	nav a {
		text-decoration: none;
		padding: 0.25em 0.5em;
	}
	.small {
		padding: 0.5em 1em;
		margin-left: 1em;
	}
	.brand {
		font-family: 'roc-grotesk-wide', sans-serif;
		margin-right: auto;
		color: var(--primary);
		font-weight: bold;
		font-size: 1.2rem;
		padding-left: 0;
	}
	@media only screen and (max-device-width: 780px) {
		.app__nav {
			padding: 1.5em 1em;
		}
		.links {
			display: none;
		}
		.burger {
			display: block;
		}
	}
	@media only screen and (max-device-width: 500px) {
		.brand {
			font-size: 1rem;
		}
	}
</style>

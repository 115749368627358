<script>
	import '$lib/styles/main.scss';
	import NavBar from '$lib/components/NavBar.svelte';
	import { dev } from '$app/environment';
	import { inject } from '@vercel/analytics';
	import { fly } from 'svelte/transition';
	import { cubicOut } from 'svelte/easing';

	inject({ mode: dev ? 'development' : 'production' });
	export let data;
</script>

<div class="app-container">
	<NavBar />
	{#key data.pathname}
		<div
			class="appMain"
			in:fly={{ y: 10, duration: 200, easing: cubicOut, delay: 200 }}
			out:fly={{ y: -10, duration: 150, easing: cubicOut, delay: 0 }}
		>
			<slot />

			<footer>
				<div class="footer-links">
					<a href="/contact">contact</a>
					<a href="/rsvp">rsvp</a>

					<a href="/menu">menu</a>
					<a target="_blank" href="/donate">donate</a>
				</div>
				<a class="dd" target="_blank" href="https://www.ronanfuruta.com/?r=rfrsite"
					>designed and developed by <span>Ronan Furuta</span></a
				>
				<small>©2024 Roots for Refugees - Refugee Education Aid</small>
			</footer>
		</div>
	{/key}
</div>

<style lang="scss">
	footer {
		padding: 2em;
		text-align: center;
	}
	footer small {
		display: block;
		text-align: center;
		margin-top: 0.5em;
	}
	.dd {
		text-decoration: none;
		font-size: 1rem;
	}
	.dd span {
		text-decoration: underline;
	}
	.footer-links {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr 1fr;
		margin-bottom: 1.75em;
	}
	.appMain {
		width: 100%;
		height: 100%;
	}
</style>
